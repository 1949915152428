import React, { useEffect, useRef, useState } from "react";
import "./login.scss";
import RegisterIcon from "../../Images/Group 21.svg";
import GIcon from "../../Images/Group 22.svg";
// import FIcon from "../../Images/Group 23.svg";
// import AIcon from "../../Images/Group 24.svg";
import otpBanner from "../../Images/otpBanner.png";
import { RiCloseLine as X } from "react-icons/ri";
import PhoneInput from "react-phone-number-input";
import { Link, useNavigate } from "react-router-dom";
import {
	googleLoginAPI,
	postResetPaswordLink,
	postUserLogin,
	sendOTP,
	verifyOTP,
} from "../../Service/user";
import AfterSuccessPopUp from "../PopUp/AfterSuccessPopUp/AfterSuccessPopUp";
import InvestorAfterSuccessPopUp from "../PopUp/InvestorAfterSuccessPopUp/InvestorAfterSuccessPopUp";
import ErrorPopUp from "../PopUp/ErrorPopUp/ErrorPopUp";
import { useDispatch, useSelector } from "react-redux";
// import { loginSuccess, loginFailure } from "../../Store/Action/userAction";
import {
	loginSuccess,
	// loginFailure,
} from "../../Store/features/user/userSlice";
import backArrow from "../../Images/left-arrow.png";
// import ResetPasswordPopUp from "../PopUp/RequestPasswordPopUp/RequestPasswordPopUp";
// import { Navigate } from "react-router-dom";
import SpinnerBS from "../Shared/Spinner/SpinnerBS";
import { selectIsMobileApp } from "../../Store/features/design/designSlice";
// import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
// import { Form } from "react-bootstrap";
import { fetchCompanyData } from "../../Store/features/user/userThunks";
import { fetchAllChats } from "../../Store/features/chat/chatThunks";

// imports fro implementing login with google
// import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
// import { jwtDecode } from "jwt-decode";
import LinkedInLogin from "./LinkedinLogin/LinkedInLogin";
// import GoogleLogin from "./GoogleLogin/GoogleLogin";
import { toast } from "react-hot-toast";
import { Toaster } from 'react-hot-toast';

function OtpVerificationModal({
	isOpen,
	setOpen,
	setShow,
	onClose,
	onVerify,
	inputValues,
	setOrderId,
}) {
	const [otp, setOtp] = useState(["", "", "", "", "", ""]);
	const inputRefs = useRef([]);

	useEffect(() => {
		if (isOpen && inputRefs.current[0]) {
			inputRefs.current[0].focus();
		}
	}, [isOpen]);

	const handleChange = (index, value) => {
		if (isNaN(value)) return;
		const newOtp = [...otp];
		newOtp[index] = value;
		setOtp(newOtp);

		if (value !== "" && index < 5) {
			inputRefs.current[index + 1].focus();
		}
	};

	const handleKeyDown = (index, e) => {
		if (e.key === "Backspace" && index > 0 && otp[index] === "") {
			inputRefs.current[index - 1].focus();
		}
	};

	const handleVerify = () => {
		if (otp.join("").length === 6) {
			onVerify(otp);
		} else {
			alert("Please enter a valid 6-digit OTP.");
		}
	};

	if (!isOpen) return null;
	const handleOtpChange = async () => {
		try {
			const response = await sendOTP(inputValues.phoneNumber);
			setOrderId(response?.orderId);
			setOpen(true);
			setShow(true);
		} catch (error) {}
	};

	return (
		<div className="OtpVerificationModal_container">
			<div
				className="OtpVerificationModal_main_container bg-white rounded-lg shadow-xl p-6  relative flex md:flex-row flex-col"
				style={{ width: "700px" }}
			>
				<div style={{ width: "90%" }}>
					<button onClick={onClose} className="Modal_Close_btn">
						<X size={30} />
					</button>
					<h2 className="enter_verification_code">Enter verification code</h2>
					<p className="Otp_Sent_Msg">
						We have just sent a verification code to your mobile number.
					</p>
					<div
						// className="flex justify-between mb-6"
						style={{
							display: "flex",
							justifyContent: "space-between",
							marginBottom: "1.5rem",
						}}
					>
						{otp.map((digit, index) => (
							<input
								key={index}
								ref={(el) => (inputRefs.current[index] = el)}
								type="text"
								maxLength="1"
								className="OTP_Input"
								value={digit}
								onChange={(e) => handleChange(index, e.target.value)}
								onKeyDown={(e) => handleKeyDown(index, e)}
							/>
						))}
					</div>
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							marginBottom: "1.5rem",
							fontSize: "14px",
							lineHeight: "20px",
						}}
					>
						<button onClick={handleOtpChange} className="Send_OTP_btn">
							Send the code again
						</button>
						<button onClick={onClose} className="Change_Number_btn">
							Change phone number
						</button>
					</div>
					<button onClick={handleVerify} className="Modal_Verify_btn active">
						Verify
					</button>
				</div>
				<img
					src={otpBanner}
					alt="Verification illustration"
					width={300}
					height={300}
					className="hidden md:block"
				/>
			</div>
		</div>
	);
}

function ResetPasswordModal({ isOpen, onClose, isInvestorSelected }) {
	const [step, setStep] = useState(1);
	const [phoneNumber, setPhoneNumber] = useState("");
	const [password, setPassword] = useState("");
	const [otp, setOtp] = useState(["", "", "", "", "", ""]);
	const [orderId, setOrderId] = useState("");
	const [status, setStatus] = useState({ type: "", message: "" });
	const inputRefs = useRef([]);
	const [isResetting, setIsResetting] = useState(false);

	useEffect(() => {
		if (step === 2 && inputRefs.current[0]) {
			inputRefs.current[0].focus();
		}
	}, [step]);

	const handleOtpChange = (index, value) => {
		if (isNaN(value)) return;
		const newOtp = [...otp];
		newOtp[index] = value;
		setOtp(newOtp);

		if (value !== "" && index < 5) {
			inputRefs.current[index + 1].focus();
		}
	};

	const handleKeyDown = (index, e) => {
		if (e.key === "Backspace" && index > 0 && otp[index] === "") {
			inputRefs.current[index - 1].focus();
		}
	};

	const handleSendOTP = async () => {
		try {
			setStatus({ type: "", message: "" });
			const response = await sendOTP(phoneNumber);
			setOrderId(response?.orderId);
			setStep(2);
			setStatus({ 
				type: "success", 
				message: "OTP sent successfully to your phone number" 
			});
		} catch (error) {
			setStatus({ 
				type: "error", 
				message: error.response?.data?.message || "Failed to send OTP. Please try again." 
			});
		}
	};

	const validatePassword = (password) => {
		return !/\s/.test(password);
	};

	const handleResetPassword = async () => {
		try {
			setIsResetting(true);
			if (!validatePassword(password)) {
				toast.error("Password cannot contain spaces");
				return;
			}

			const verificationCode = otp.join("");
			const res = await verifyOTP({
				otp: verificationCode,
				orderId,
				phoneNumber: phoneNumber,
			});
			if (res.isOTPVerified) {
				await postResetPaswordLink({
					phoneNumber: phoneNumber,
					password: password
				});
				
				toast.success("Password reset successful!");
				onClose();
			}
			else {
				toast.error("Incorrect OTP. Please try again.");
			}
		} catch (error) {
			toast.error(error.response?.data?.message || "Incorrect OTP. Please try again.");
		} finally {
			setIsResetting(false);
		}
	};

	if (!isOpen) return null;

	return (
		<div className="OtpVerificationModal_container">
			<div className="OtpVerificationModal_main_container bg-white rounded-lg shadow-xl p-6 relative flex md:flex-row flex-col">
				<div style={{ width: "90%" }}>
					<button onClick={onClose} className="Modal_Close_btn">
						<X size={30} />
					</button>
					
					{status.message && (
						<div className={`status-message ${status.type}`}>
							{status.message}
						</div>
					)}
					
					{step === 1 ? (
						<>
							<h2 className="enter_verification_code">Reset Password</h2>
							<p className="Otp_Sent_Msg">Enter your phone number to reset password</p>
							<div className="mb-4">
								<PhoneInput
									placeholder="Mobile Number"
									className="form-control plato_form_control rounded-3"
									defaultCountry="IN"
									countryCallingCodeEditable={false}
									initialValueFormat="national"
									autoComplete="off"
									onChange={(value) => setPhoneNumber(value)}
									value={phoneNumber}
									countrySelectProps={{ native: "true", style: { display: "none" } }}
									international={false}
								/>
							</div>
							<button 
								onClick={handleSendOTP} 
								className={`Modal_Verify_btn ${isInvestorSelected ? "investor" : "startup"}`}
							>
								Send OTP
							</button>
						</>
					) : (
						<>
							<h2 className="enter_verification_code">Enter New Password</h2>
							<div className="mb-4">
								<input
									type="password"
									placeholder="New Password"
									className="form-control rounded-3 mb-4"
									value={password}
									onChange={(e) => setPassword(e.target.value)}
								/>
								<p className="Otp_Sent_Msg">Enter the verification code sent to your phone</p>
								<div style={{ display: "flex", justifyContent: "space-between", marginBottom: "1.5rem" }}>
									{otp.map((digit, index) => (
										<input
											key={index}
											ref={(el) => (inputRefs.current[index] = el)}
											type="text"
											maxLength="1"
											className="OTP_Input"
											value={digit}
											onChange={(e) => handleOtpChange(index, e.target.value)}
											onKeyDown={(e) => handleKeyDown(index, e)}
										/>
									))}
								</div>
							</div>
							<button 
								onClick={handleResetPassword} 
								className={`Modal_Verify_btn ${isInvestorSelected ? "investor" : "startup"}`}
								disabled={isResetting}
							>
								{isResetting ? "Verifying..." : "Reset Password"}
							</button>
						</>
					)}
				</div>
				<img
					src={otpBanner}
					alt="Reset password illustration"
					width={300}
					height={300}
					className="hidden md:block"
				/>
			</div>
		</div>
	);
}

const Login = () => {
	// const loggedInUser = useSelector((state) => state.user.loggedInUser);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [open, setOpen] = useState(false);

	const isMobileApp = useSelector(selectIsMobileApp);

	// States for login
	const [isLoginSuccessfull, setIsLoginSuccessfull] = useState(false);
	const [isInvestorSelected, setIsInvestorSelected] = useState(false);
	const [error, setError] = useState(null);
	const [show, setShow] = useState(false);
	const [orderId, setOrderId] = useState("");
	const userVisitCount = localStorage.getItem("userVisit");
	const [isMobileLogin, setIsMobileLogin] = useState(true);
	// const otpInputRefs = useRef([]);
	// const [otp, setOtp] = useState(["", "", "", "", "", ""]);
	const [showErrorPopup, setShowErrorPopup] = useState(false);
	const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
	const [inputValues, setInputValues] = useState({
		password: "",
		usernameOrEmail: "",
		phoneNumber: "",
	});
	const [staySignedIn, setStaySignedIn] = useState(false);

	const handleGoogleLogin = async () => {
		try {
			const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
			// const redirectUri = process.env.REACT_APP_GOOGLE_REDIRECT_URI;
			const redirectUri = process.env.REACT_APP_GOOGLE_REDIRECT_URI;

			// Construct the authorization URL with all required scopes
			const authUrl = new URL("https://accounts.google.com/o/oauth2/v2/auth");
			authUrl.searchParams.append("client_id", clientId);
			authUrl.searchParams.append("redirect_uri", redirectUri);
			authUrl.searchParams.append("response_type", "code");
			authUrl.searchParams.append("access_type", "offline");
			authUrl.searchParams.append("prompt", "consent"); // Force prompt to get refresh token
			authUrl.searchParams.append(
				"scope",
				[
					"https://www.googleapis.com/auth/userinfo.profile",
					"https://www.googleapis.com/auth/userinfo.email",
					"https://www.googleapis.com/auth/calendar.events",
					// "https://www.googleapis.com/auth/calendar.app.created",
				].join(" ")
			);

			// Redirect to Google's OAuth page
			window.location.href = authUrl.toString();
		} catch (error) {
			console.error("Error initiating Google login:", error);
			setError("Failed to initiate Google login");
		}
	};

	// Function to exchange authorization code for tokens
	const exchangeCodeForTokens = async (code) => {
		try {
			const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
			const clientSecret = process.env.REACT_APP_GOOGLE_CLIENT_SECRET;
			const redirectUri = process.env.REACT_APP_GOOGLE_REDIRECT_URI;

			// Exchange code for tokens
			const tokenResponse = await fetch("https://oauth2.googleapis.com/token", {
				method: "POST",
				headers: {
					"Content-Type": "application/x-www-form-urlencoded",
				},
				body: new URLSearchParams({
					code,
					client_id: clientId,
					client_secret: clientSecret,
					redirect_uri: redirectUri,
					grant_type: "authorization_code",
				}),
			});

			if (!tokenResponse.ok) {
				throw new Error("Failed to exchange code for tokens");
			}

			const tokens = await tokenResponse.json();

			// Send tokens to your backend
			const response = await googleLoginAPI({
				access_token: tokens.access_token,
				refresh_token: tokens.refresh_token,
				id_token: tokens.id_token,
			});

			if (response.status === 200) {
				const { user, token } = response;

				// Handle successful login similar to existing code
				if (!userVisitCount) {
					localStorage.setItem("userVisit", 1);
				} else {
					localStorage.setItem("userVisit", 2);
				}

				localStorage.setItem("accessToken", token);
				localStorage.setItem("isLoggedIn", "true");

				if (!isInvestorSelected && user.isInvestor === "true") {
					setError("Invalid credentials");
					return;
				}
				if (isInvestorSelected && user.isInvestor === "false") {
					setError("Invalid credentials");
					return;
				}

				const storedAccountsKey =
					user.isInvestor === "true" ? "InvestorAccounts" : "StartupAccounts";
				const storedAccounts =
					JSON.parse(localStorage.getItem(storedAccountsKey)) || [];

				if (
					!storedAccounts.some((account) => account.user?._id === user?._id)
				) {
					storedAccounts.push(response);
					localStorage.setItem(
						storedAccountsKey,
						JSON.stringify(storedAccounts)
					);
				}

				setIsLoginSuccessfull(true);

				setTimeout(() => {
					setIsInvestorSelected(false);
					setIsLoginSuccessfull(false);
					if (!user.isInvestor) navigate("/home");
					else navigate("/investor/home");
				}, 2000);

				dispatch(loginSuccess(user));

				const isInvestor = user.isInvestor === "true";
				if (isInvestor) {
					dispatch(fetchCompanyData(user.investor, isInvestor));
				} else {
					dispatch(fetchCompanyData(user._id, isInvestor));
				}

				dispatch(fetchAllChats());
			}
		} catch (error) {
			console.error("Error during token exchange:", error);
		}
	};

	// Handle OAuth redirect
	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const code = urlParams.get("code");

		if (code) {
			exchangeCodeForTokens(code);
			// Clean up URL
			window.history.replaceState({}, document.title, window.location.pathname);
		}
	}, []);

	const handleCheckboxChange = () => {
		setStaySignedIn(!staySignedIn);
	};

	const isValidMobileNumber = (phoneNumber) => {
		// Remove any non-digit characters from the input
		const cleanedNumber = phoneNumber.replace(/\D/g, "");

		// Check if the cleaned number starts with the country code for India (+91) and has 10 digits
		return /^91\d{10}$/.test(cleanedNumber);
	};

	// Handle Input change
	const handleInputChange = (event, type) => {
		if (type !== "country" && type !== "state" && type !== "phoneNumber") {
			const { name, value } = event.target;
			setInputValues({ ...inputValues, [name]: value });
		} else if (type === "country") {
			setInputValues({ ...inputValues, country: event });
		} else if (type === "state") {
			setInputValues({ ...inputValues, state: event });
		} else if (type === "phoneNumber") {
			setInputValues({ ...inputValues, phoneNumber: event });
		}
	};

	const toggleLoginMethod = () => {
		setIsMobileLogin(!isMobileLogin);
		setShow(false);
	};

	const handleFormSubmit = async (event) => {
		event.preventDefault();
		if (isMobileLogin && !isValidMobileNumber(inputValues.phoneNumber)) {
			setShowErrorPopup(true);
			setTimeout(() => {
				setShowErrorPopup(false);
			}, 2000);

			return;
		}
		try {
			if (isMobileLogin) {
				if (isValidMobileNumber(inputValues.phoneNumber)) {
					// Implement your mobile number verification logic here
					if (
						inputValues.phoneNumber === "" ||
						inputValues.phoneNumber.length < 10
					)
						return;
					const res = await sendOTP(inputValues.phoneNumber);
					setOrderId(res?.orderId);
					setOpen(true);
					setShow(true);
				} else {
					// Handle invalid phone number scenario
				}
			} else {
				const response = await postUserLogin({
					phoneNumber: inputValues.usernameOrEmail,
					password: inputValues.password,
				});
				const user = response.user;
				const token = response.token;
				if (!userVisitCount) {
					localStorage.setItem("userVisit", 1);
				} else {
					localStorage.setItem("userVisit", 2);
				}
				localStorage.setItem("accessToken", token);
				localStorage.setItem("isLoggedIn", "true");
				if (response) {
					if (!isInvestorSelected && user.isInvestor === "true") {
						setError("Invalid credentials");
						return;
					}
					if (isInvestorSelected && user.isInvestor === "false") {
						setError("Invalid credentials");
						return;
					}

					const storedAccountsKey =
						user.isInvestor === "true" ? "InvestorAccounts" : "StartupAccounts";
					const storedAccounts =
						JSON.parse(localStorage.getItem(storedAccountsKey)) || [];
					const isAccountExists = storedAccounts.some(
						(account) => account.user?._id === user?._id
					);

					if (!isAccountExists) {
						storedAccounts.push(response);
						localStorage.setItem(
							storedAccountsKey,
							JSON.stringify(storedAccounts)
						);
					}

					setIsLoginSuccessfull(true);

					setTimeout(() => {
						setIsInvestorSelected(false);
						setIsLoginSuccessfull(false);

						if (!user.investor) navigate("/home");
						else navigate("/investor/home");
					}, 2000);

					dispatch(loginSuccess(response?.user));

					let isInvestor = response?.user?.isInvestor === "true" ? true : false;
					if (isInvestor) {
						dispatch(fetchCompanyData(response?.user?.investor, isInvestor));
					} else {
						dispatch(fetchCompanyData(response?.user?._id, isInvestor));
					}

					dispatch(fetchAllChats());
				}
			}
		} catch (error) {
			// Set the error message to be displayed in the component
			setError(
				error.response?.data?.message || "Login failed. Please try again."
			);
		} finally {
			setLoading(false);
		}
	};

	const ValidateOtp = async (otp) => {
		setLoading(true);
		try {
			const verificationCode = otp.join("");
			const res = await verifyOTP({
				otp: verificationCode,
				orderId,
				phoneNumber: inputValues.phoneNumber,
			});

			if (res.isOTPVerified) {
				// Proceed with login if OTP is verified
				const response = await postUserLogin(inputValues);
				const user = response.user;
				const token = response.token;
				if (!userVisitCount) {
					localStorage.setItem("userVisit", 1);
				} else {
					localStorage.setItem("userVisit", 2);
				}
				localStorage.setItem("accessToken", token);
				localStorage.setItem("isLoggedIn", "true");
				if (response) {
					if (!isInvestorSelected && user.isInvestor === "true") {
						setError("Invalid credentials");
						return;
					}
					if (isInvestorSelected && user.isInvestor === "false") {
						setError("Invalid credentials");
						return;
					}

					const storedAccountsKey =
						user.isInvestor === "true" ? "InvestorAccounts" : "StartupAccounts";
					const storedAccounts =
						JSON.parse(localStorage.getItem(storedAccountsKey)) || [];
					const isAccountExists = storedAccounts.some(
						(account) => account.user?._id === user?._id
					);

					if (!isAccountExists) {
						storedAccounts.push(response);
						localStorage.setItem(
							storedAccountsKey,
							JSON.stringify(storedAccounts)
						);
					}

					setIsLoginSuccessfull(true);

					setTimeout(() => {
						setIsInvestorSelected(false);
						setIsLoginSuccessfull(false);

						if (!user.investor) navigate("/home");
						else navigate("/investor/home");
					}, 2000);

					dispatch(loginSuccess(response?.user));

					let isInvestor = response?.user?.isInvestor === "true" ? true : false;
					if (isInvestor) {
						dispatch(fetchCompanyData(response?.user?.investor, isInvestor));
					} else {
						dispatch(fetchCompanyData(response?.user?._id, isInvestor));
					}

					dispatch(fetchAllChats());
				}
			} else {
				throw new Error("Incorrect OTP. Please try again.");
			}
		} catch (error) {
			toast.error(error.response?.data?.message || "Incorrect OTP. Please try again.");
		} finally {
			setLoading(false);
		}
	};

	const handleClosePopup = () => {
		if (!isInvestorSelected) {
			navigate("/home");
		} else if (isInvestorSelected) {
			navigate("/investor/home");
		}
	};

	useEffect(() => {
		document.title = "Log In | The Capital Hub";
	}, []);

	// Function to validate password for spaces
	const validatePassword = (password) => {
		return !/\s/.test(password);
	};

	return (
		<div className="register_container">
			<Toaster
				position="top-center"
				reverseOrder={false}
				toastOptions={{
					// Default toast options
					duration: 3000,
					style: {
						background: '#363636',
						color: '#fff',
					},
					success: {
						style: {
							background: '#22c55e',
						},
					},
					error: {
						style: {
							background: '#ef4444',
						},
					},
				}}
			/>
			{/* Left side colomn */}
			<div className="register_container_left register_heading">
				<Link to="/">
					<img
						className="backArrow"
						src={backArrow}
						style={{ cursor: "pointer", height: 40, width: 40 }}
						alt="arrow_back"
					/>
				</Link>
				<img
					src={RegisterIcon}
					alt="register"
					className="img-fluid"
					loading="eager"
				/>
				<h2 className="text-4xl font-bold mb-4">New Here ?</h2>
				<p className="text-xl mb-8">Create a account here</p>
				<Link
					// className="create_account_btn"
					className={`create_account_btn ${
						!isInvestorSelected ? "startup" : "investor"
					} `}
					to={"/signup"}
				>
					Create account
				</Link>
			</div>

			{/* Right side colomn */}
			<div className="register_container_right register_heading_right">
				{!isMobileApp && (
					<Link className="d-md-none" to="/">
						<img className="backArrow" src={backArrow} alt="arrow_back" />
					</Link>
				)}
				<span className="welcome w-100 text-center">Welcome back!</span>

				<div className="login_buttons_row d-flex flex-column align-items-center gap-3">
					<h1 className="mt-5">Login to your account</h1>
					<div className="d-flex flex-row justify-content-between align-items-center gap-4 gap-sm-5">
						<Link to="">
							<button
								className={`login_btn ${!isInvestorSelected ? "startup" : ""} `}
								onClick={() => setIsInvestorSelected(false)}
								style={{ width: 225, height: 41.6 }}
							>
								Start Up
							</button>
						</Link>
						<Link to="">
							<button
								className={`login_btn ${isInvestorSelected ? "investor" : ""} `}
								onClick={() => setIsInvestorSelected(true)}
								style={{ width: 225, height: 41.6 }}
							>
								Investor
							</button>
						</Link>
					</div>
				</div>

				{/* <h3 className="already_have_account" style={{ paddingTop: "0.5rem" }}>
						Don't have an account?{" "}
						<Link
							to={"/signup"}
							className={isInvestorSelected ? "green" : "orange"}
						>
							Create account
						</Link>
					</h3> */}
				<p className="text-center">Login using social networks</p>

				<div
					className="flex justify-center space-x-4"
					style={{ display: "flex", gap: 10 }}
				>
					{/* <GoogleLogin
						isInvestorSelected={isInvestorSelected}
						setIsLoginSuccessfull={setIsLoginSuccessfull}
						setError={setError}
						setIsInvestorSelected={setIsInvestorSelected}
					/> */}
					<button
						// className="p-2 bg-white border border-gray-300 rounded-full"
						style={{
							padding: "0.5rem",
							backgroundColor: "white",
							border: "none",
						}}
						onClick={handleGoogleLogin}
					>
						<img src={GIcon} alt="Google" width={35} height={35} />
					</button>
					{/* <GoogleLogin
						onSuccess={handleGoogleLoginSuccess}
						onError={handleGoogleLoginError}
						useOneTap
					/> */}
					<LinkedInLogin
						isInvestorSelected={isInvestorSelected}
						setIsLoginSuccessfull={setIsLoginSuccessfull}
						setIsInvestorSelected={setIsInvestorSelected}
						setError={setError}
						text={"Sign in with Linkedin"}
						REDIRECT_URI={"https://thecapitalhub.in/login"}
					/>
					{/* <button
						className="p-2 bg-white border border-gray-300 rounded-full"
						onClick={handleGoogleLogin}
					>
						<img src={GIcon} alt="Google" width={24} height={24} />
					</button> */}
					{/* <button className="p-2 bg-white border border-gray-300 rounded-full">
							<img src={FIcon} alt="Facebook" width={24} height={24} />
						</button> */}
				</div>

				<div className="flex items-center">
					<div className="flex-grow border-t border-gray-300"></div>
					<span className="flex-shrink mx-4 text-gray-600">Or</span>
					<div className="flex-grow border-t border-gray-300"></div>
				</div>

				<div className="d-flex justify-content-center mb-3">
					<button className="login_btn" onClick={toggleLoginMethod}>
						{isMobileLogin ? "Use Username/Email" : "Use Mobile Number"}
					</button>
				</div>

				{!show && (
					<form
						onSubmit={handleFormSubmit}
						className="d-flex flex-column gap-2 login-form-mobile"
						// style={{ width: "60%" }}
					>
						{isMobileLogin ? (
							<div className="row">
								<div className="col-md-12 col input-container">
									<label htmlFor="mobile">Mobile Number</label>
									<PhoneInput
										placeholder="Mobile Number"
										className="form-control plato_form_control rounded-3"
										defaultCountry="IN"
										countryCallingCodeEditable={false}
										initialValueFormat="national"
										autoComplete="off"
										onChange={(e) => {
											handleInputChange(e, "phoneNumber");
										}}
										value={inputValues.phoneNumber}
										countrySelectProps={{
											native: "true",
											style: { display: "none" },
										}}
										international={false}
									/>
								</div>
							</div>
						) : (
							<>
								<div className="row">
									<div className="col-md-12 col input-container">
										<input
											placeholder="Username or Email"
											className="form-control plato_form_control rounded-3"
											onChange={(e) => {
												const email = e.target.value;
												setInputValues((prev) => ({
													...prev,
													usernameOrEmail: email,
												}));
											}}
											value={inputValues?.usernameOrEmail}
											name="phoneNumber"
										/>
									</div>
								</div>
								<div className="row">
									<div className="col-md-12">
										<input
											type="password"
											id="password"
											name="password"
											className="form-control rounded-3"
											required
											placeholder="Password"
											onChange={(e) => {
												const password = e.target.value;
												setInputValues((prev) => ({
													...prev,
													password: password,
												}));
												if (!validatePassword(password)) {
													setError(
														"Invalid Password. Password cannot contain spaces"
													);
												} else {
													setError("");
												}
											}}
											value={inputValues?.password}
										/>
									</div>
								</div>
							</>
						)}

						<div className="row mt-2 staySignedIn-fp">
							<div className="d-flex gap-2 p-2">
								<input
									type="checkbox"
									id="staySignedInCheckbox"
									checked={staySignedIn}
									onChange={handleCheckboxChange}
								/>
								<label htmlFor="staySignedInCheckbox">Stay signed in</label>
							</div>
							{!isMobileLogin && (
								<div className="col-md-12">
									<Link to={""} onClick={() => setShowResetPasswordModal(true)}>
										Forgot Password?
									</Link>
								</div>
							)}
						</div>

						<div className="submit_btn mt-2">
							<button
								type="submit"
								className={` ${isInvestorSelected ? "investor" : "startup"}`}
								style={{
									width: 225,
									height: 41.6,
									borderRadius: 20,
									fontSize: 16,
									fontWeight: 700,
									color: "#fff",
								}}
							>
								{loading ? (
									<SpinnerBS spinnerSizeClass="spinner-border-sm"></SpinnerBS>
								) : isMobileLogin ? (
									"Send OTP"
								) : (
									"Login"
								)}
							</button>
						</div>

						<h3 className="already_have_account_mobile">
							I don't have an account? &nbsp;
							<Link to={"/signup"} style={{ color: "red" }}>
								Create account
							</Link>
						</h3>
					</form>
				)}

				<OtpVerificationModal
					isOpen={show}
					setOpen={setShow}
					setShow={setShow}
					onClose={() => setShow(false)}
					onVerify={ValidateOtp}
					phoneNumber={inputValues.phoneNumber}
					sendOTP={sendOTP}
					inputValues={inputValues}
					setOrderId={setOrderId}
				/>

				{showResetPasswordModal && (
					<ResetPasswordModal
						isOpen={showResetPasswordModal}
						onClose={() => setShowResetPasswordModal(false)}
						isInvestorSelected={isInvestorSelected}
					/>
				)}
			</div>
			{isLoginSuccessfull && !isInvestorSelected && (
				<AfterSuccessPopUp onClose={handleClosePopup} login={true} />
			)}
			{open && (
				<AfterSuccessPopUp
					withoutOkButton
					onClose={() => setOpen(!open)}
					successText="OTP Send successfully to the mobile"
				/>
			)}
			{isLoginSuccessfull && isInvestorSelected && (
				<InvestorAfterSuccessPopUp onClose={handleClosePopup} login={true} />
			)}

			{error && <ErrorPopUp message={error} onClose={() => setError(null)} />}

			{showErrorPopup && (
				<ErrorPopUp
					message={"Invalid mobile number. Please enter a valid mobile number."}
					onClose={() => setShowErrorPopup(false)}
				/>
			)}
		</div>
	);
};

export default Login;
