import React, { useEffect } from 'react';
import { FaLinkedin } from 'react-icons/fa';
import './About.scss';
import { useNavigate } from "react-router-dom"; 
import avatar4 from "../../../../Images/avatars/image-1.png";


const About = ({ community }) => {
  const navigate = useNavigate();


  const handleAdminClick = (username) => {
    navigate(`/user/${username}`);
  };

 
  const admin = community?.adminId;
  
  const defaultBanner = "https://s3-alpha-sig.figma.com/img/ae22/914b/3deb64aec2c85137192e7c0738634eda?Expires=1739750400&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=VZIEZOvSCBExfhho7IZsrXiC-hQgDWQgYcAtPhqQU6lh0XgV6ORHNuIXm7Spwwz-kCkYqesvClv3RqPgv9BHVYrLa~q85GVRr5-2YdG5alPKph2cIS5g4SiEZmyuOzEr67lBc7Mm5JHkuSowlRqd-2sLGjqgm3bA3r0wKAfjaTTHopxP39XoVfJGHy60O~bNYZwwMprDe-7NPsT5eLzy2~Mn03qz8ZrOHvLMTCvwC-Jh8Do4D4yQ~3mWUs--gqqDB0FxqEYsh6bZyQXJ7uP0~WIi9oqtcaT-Mj9wsSTw~QTogK9b6VE1LFQsT1wsy~GWp0rorP96~OlLwE4vmw3wew__";

  const createMarkup = (content) => {
    return { __html: content };
  };

  return (
    <div className="about-container">
      <div className="community-banner">
        <img src={community?.banner_image || defaultBanner} alt="Community Banner" />
      </div>

      <div className="community-info">
        <div className="community-header">
          <img src={community?.image} alt={community?.name} className="community-logo" />
          <h1>{community?.name}</h1>
        </div>

        <div className="content-section">
          <div className="description-section">
            <h2 className='community-heading'>About Community</h2>
            <div 
              className="quill-content"
              dangerouslySetInnerHTML={createMarkup(community?.description)}
            />
            <div 
              className="quill-content"
              dangerouslySetInnerHTML={createMarkup(community?.about)}
            />
          </div>

          <div className="admin-section">
            <h2 className='community-heading'>Community Admin</h2>
            {admin && (
              <div className="admin-card" onClick={() => handleAdminClick(admin.userName)}>
                <img src={admin.profilePicture || avatar4} alt={`${admin.firstName} ${admin.lastName}`} />
                <div className="admin-info">
                  <h3 style={{ display: 'flex', alignItems: 'center' }}>
                    {admin.firstName} {admin.lastName}
                    {admin.linkedin && (
                      <a 
                        href={admin.linkedin} 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className="linkedin-link"
                        style={{ marginLeft: '8px' }}
                        onClick={(e) => e.stopPropagation()}
                      >
                        <FaLinkedin />
                      </a>
                    )}
                  </h3>
                  
                  <p className="company">
                    {admin.startUp?.company || admin.investor?.companyName || 'Company not specified'}
                  </p>
                  <p className="designation">{admin.designation || 'Designation not specified'}</p>
                  <p className="location">{admin.location || 'Location not specified'}</p>
                  <p className="bio">{admin.bio || 'No bio available'}</p>
                  
                </div>
              </div>
            )}
          </div>

          <div className="terms-section">
            <h2 className='community-heading'>Terms and Conditions</h2>
            {Array.isArray(community?.terms_and_conditions) && community.terms_and_conditions.length > 0 ? (
              <ul>
                {community.terms_and_conditions.map((term, index) => (
                  <li key={index}>{term}</li>
                ))}
              </ul>
            ) : (
              <p>No terms and conditions available</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default About; 