import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { environment } from "../../../environments/environment";
import "./UpdateCommunityForm.scss";
import { getBase64 } from "../../../utils/getBase64";
import { toast, ToastContainer } from 'react-toastify';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material';
import { sendOTP, verifyOTP } from "../../../Service/user";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


const UpdateCommunityForm = ({ community }) => {

  const defaultBanner = "https://s3-alpha-sig.figma.com/img/ae22/914b/3deb64aec2c85137192e7c0738634eda?Expires=1739750400&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=VZIEZOvSCBExfhho7IZsrXiC-hQgDWQgYcAtPhqQU6lh0XgV6ORHNuIXm7Spwwz-kCkYqesvClv3RqPgv9BHVYrLa~q85GVRr5-2YdG5alPKph2cIS5g4SiEZmyuOzEr67lBc7Mm5JHkuSowlRqd-2sLGjqgm3bA3r0wKAfjaTTHopxP39XoVfJGHy60O~bNYZwwMprDe-7NPsT5eLzy2~Mn03qz8ZrOHvLMTCvwC-Jh8Do4D4yQ~3mWUs--gqqDB0FxqEYsh6bZyQXJ7uP0~WIi9oqtcaT-Mj9wsSTw~QTogK9b6VE1LFQsT1wsy~GWp0rorP96~OlLwE4vmw3wew__";

  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: community.name || "",
    image: community.image || "",
    banner_image: community.banner_image || defaultBanner,
    size: community.size || "",
    subscription: community.subscription || "",
    members: community.members || [],
    amount: community.amount || "",
    isOpen: community.isOpen || false,
    about: community.about || "",
    terms_and_conditions: community.terms_and_conditions || [""],
    whatsapp_group_link: community.whatsapp_group_link || ""
  });


  const token = localStorage.getItem("accessToken");

  const communitySizeOptions = [
    "Less than 100k",
    "100k-200k",
    "200k-500k",
    "500k+"
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deletionReason, setDeletionReason] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [otpDialogOpen, setOtpDialogOpen] = useState(false);
  const [otp, setOtp] = useState('');
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [orderId, setOrderId] = useState('');
  const [isVerifyingOtp, setIsVerifyingOtp] = useState(false);

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, 3, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      ['link', 'emoji'],
      ['clean']
    ]
  };

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike',
    'list', 'bullet',
    'link', 'emoji'
  ];

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name === 'subscription') {
      setFormData({
        ...formData,
        subscription: value,
        amount: value === 'free' ? null : formData.amount // Keep existing amount if paid, null if free
      });
    } else if (name === 'amount') {
      setFormData({
        ...formData,
        amount: value === '' ? null : Number(value) // Convert empty string to null, otherwise to number
      });
    } else {
      setFormData({
        ...formData,
        [name]: type === 'checkbox' ? checked : value
      });
    }
  };

  const handleFileChange = (event) => {
    const { name, files } = event.target;
    setFormData({ ...formData, [name]: files[0] });
  };

  const handleSubmit = async (e) => {
    console.log(formData);
    e.preventDefault();
    setIsLoading(true);
    // Convert both images to base64 if they're files
    let updatedFormData = { ...formData };
    if (formData.image instanceof File) {
      updatedFormData.image = await getBase64(formData.image);
    }
    if (formData.banner_image instanceof File) {
      updatedFormData.banner_image = await getBase64(formData.banner_image);
    }
    
    try {
      const response = await axios.patch(
        `${environment.baseUrl}/Communities/updateCommunity/${community._id}`,
        updatedFormData,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      toast.success("Community updated successfully!");
      console.log(response.data);
    } catch (error) {
      toast.error("Error updating community: " + (error.response?.data?.message || 'Unknown error'));
      console.error("Error updating community:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleTermChange = (index, value) => {
    const newTerms = [...formData.terms_and_conditions];
    newTerms[index] = value;
    setFormData({ ...formData, terms_and_conditions: newTerms });
  };

  const addNewTerm = () => {
    setFormData({
      ...formData,
      terms_and_conditions: [...formData.terms_and_conditions, ""]
    });
  };

  const removeTerm = (index) => {
    const newTerms = formData.terms_and_conditions.filter((_, i) => i !== index);
    setFormData({ ...formData, terms_and_conditions: newTerms });
  };

  const handleDelete = async () => {
    if (isDeleting) return;
    setIsDeleting(true);
    
    try {
      // Send OTP using the same service as Login page
      const response = await sendOTP(community.adminId.phoneNumber);

      if (response?.orderId) {
        setOrderId(response.orderId);
        setOtpDialogOpen(true);
        toast.info('Please enter the OTP sent to your phone');
      } else {
        throw new Error('Failed to send OTP');
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to send OTP');
    } finally {
      setIsDeleting(false);
      setDeleteDialogOpen(false);
    }
  };

  const handleOtpSubmit = async () => {
    setIsVerifyingOtp(true);
    try {
      const verifyResponse = await verifyOTP({
        otp: otp,
        orderId: orderId,
        phoneNumber: community.adminId.phoneNumber
      });

      if (verifyResponse.isOTPVerified) {
        // If OTP is verified, proceed with community deletion
        const deleteResponse = await axios.delete(
          `${environment.baseUrl}/communities/softDelete/${community._id}`,
          {
            data: { reason: deletionReason },
            headers: { Authorization: `Bearer ${token}` }
          }
        );

        if (deleteResponse.data.status === 200) {
          toast.success('Community deleted successfully');
          navigate('/ExploreCommunities');
        }
      } else {
        toast.error('Invalid OTP');
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Invalid OTP or error occurred');
    } finally {
      setIsVerifyingOtp(false);
      setOtpDialogOpen(false);
      setOtp('');
    }
  };

  const handleImageClick = (inputName) => {
    const fileInput = document.getElementById(inputName);
    fileInput.click();
  };

  const handleQuillChange = (value) => {
    setFormData(prev => ({
      ...prev,
      about: value
    }));
  };

  return (
    <div className="update-community-form">
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} />
      <h2>Update Community Settings</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Community Name</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Enter community name"
          />
        </div>

        <div className="image-upload-container">
          <div className="profile-image-container" onClick={() => handleImageClick('image-input')}>
            <img 
              src={formData.image instanceof File ? URL.createObjectURL(formData.image) : formData.image} 
              alt="Community Profile"
              className="profile-image"
            />
            <div className="edit-overlay">
              <span>Edit Profile Image</span>
            </div>
            <input
              type="file"
              id="image-input"
              name="image"
              onChange={handleFileChange}
              accept="image/*"
              hidden
            />
          </div>

          <div className="banner-image-container" onClick={() => handleImageClick('banner-input')}>
            <img 
              src={formData.banner_image instanceof File ? URL.createObjectURL(formData.banner_image) : formData.banner_image} 
              alt="Community Banner"
              className="banner-image"
            />
            <div className="edit-overlay">
              <span>Edit Banner Image</span>
            </div>
            <input
              type="file"
              id="banner-input"
              name="banner_image"
              onChange={handleFileChange}
              accept="image/*"
              hidden
            />
          </div>
        </div>

        <div className="form-group">
          <label>Community Size</label>
          <select 
            name="size" 
            value={formData.size}
            onChange={handleChange}
          >
            <option value="">Select community size</option>
            {communitySizeOptions.map((size) => (
              <option key={size} value={size}>{size}</option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label>Subscription Type</label>
          <select 
            name="subscription" 
            value={formData.subscription}
            onChange={handleChange}
          >
            <option value="">Select subscription type</option>
            <option value="free">Free</option>
            <option value="paid">Paid</option>
          </select>
        </div>

        {formData.subscription === 'paid' && (
          <div className="form-group">
            <label>Subscription Amount</label>
            <input
              type="number"
              name="amount"
              value={formData.amount}
              onChange={handleChange}
              placeholder="Enter amount"
            />
          </div>
        )}

        <div className="form-group checkbox">
          <label>
            <input
              type="checkbox"
              name="isOpen"
              checked={formData.isOpen}
              onChange={handleChange}
            />
            Is Community Open?
          </label>
        </div>

        <div className="form-group">
          <label>About Community</label>
          <ReactQuill
            theme="snow"
            value={formData.about}
            onChange={handleQuillChange}
            modules={modules}
            formats={formats}
            placeholder="Enter community description"
          />
        </div>
        <div className="form-group">
          <label>Whatsapp Group Link</label>
          <input
            type="text"
            name="whatsapp_group_link"
            value={formData.whatsapp_group_link}
            onChange={handleChange}
            placeholder="Enter whatsapp group link"
          />
        </div>

        <div className="form-group">
          <label>Terms and Conditions</label>
          {formData.terms_and_conditions.map((term, index) => (
            <div key={index} className="term-input-group">
              <input
                type="text"
                value={term}
                onChange={(e) => handleTermChange(index, e.target.value)}
                placeholder={`Term ${index + 1}`}
              />
              {formData.terms_and_conditions.length > 1 && (
                <button 
                  type="button" 
                  className="remove-term"
                  onClick={() => removeTerm(index)}
                >
                  Remove
                </button>
              )}
            </div>
          ))}
          <button 
            type="button" 
            className="add-term"
            onClick={addNewTerm}
          >
            Add Term
          </button>
        </div>

        <button type="submit" >{isLoading ? ("Updating...") : ( "Update Community")}</button>
      </form>

      <button 
        className="delete-button"
        onClick={() => setDeleteDialogOpen(true)}
      >
        Delete Community
      </button>

      <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
        <DialogTitle>Delete Community</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to delete this community? This action cannot be undone.</p>
          <TextField
            fullWidth
            multiline
            rows={4}
            label="Reason for deletion"
            value={deletionReason}
            onChange={(e) => setDeletionReason(e.target.value)}
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button 
            onClick={handleDelete} 
            color="error"
            disabled={isDeleting}
          >
            {isDeleting ? 'Deleting...' : 'Delete'}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={otpDialogOpen} onClose={() => setOtpDialogOpen(false)}>
        <DialogTitle>Enter OTP</DialogTitle>
        <DialogContent>
          <p>Please enter the OTP sent to your phone number</p>
          <TextField
            fullWidth
            label="OTP"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOtpDialogOpen(false)}>Cancel</Button>
          <Button 
            onClick={handleOtpSubmit}
            color="primary"
            variant="contained"
            disabled={isVerifyingOtp}
          >
            {isVerifyingOtp ? 'Verifying...' : 'Verify & Delete'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UpdateCommunityForm; 