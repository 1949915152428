import React, { useState, useEffect } from "react";
import {
    FaCalendarAlt,
    FaVideo,
    FaChevronLeft,
    FaChevronRight,
} from "react-icons/fa";
import { ImPriceTags } from "react-icons/im";
import { useSelector } from "react-redux";
import { selectTheme } from "../../../Store/features/design/designSlice";
import Calendar from "react-calendar";
import "./ScheduleMeeting.scss";
import { useParams } from "react-router-dom";
import { load } from "@cashfreepayments/cashfree-js";
import { environment } from "../../../environments/environment";
import avatar4 from "../../../Images/avatars/image.png";
import { toast } from "react-toastify";
import PurchasePopup from "../../../components/Shared/PurchasePopup/PurchasePopup";
import  Navbar  from "../../../components/Navbar/Navbar3/Navbar3";
const baseUrl = environment.baseUrl;

const MeetingScheduler = () => {
    const [isBooked, setIsBooked] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedTime, setSelectedTime] = useState(null);
    const [currentDate, setCurrentDate] = useState(new Date());
    const { username, meetingId } = useParams();
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState({});
    const [availability, setAvailability] = useState({});
    const [events, setEvents] = useState([]);
    const [meetingLink, setMeetingLink] = useState("");
    const [error, setError] = useState("");
    const [orderId, setOrderId] = useState("");
    const [paymentStatus, setPaymentStatus] = useState(null);
    const [showDetailsForm, setShowDetailsForm] = useState(false);
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [meetingDetails, setMeetingDetails] = useState(null);
    const theme = useSelector(selectTheme);

    useEffect(() => {
        const fetchSchedulePageData = async () => {
            try {
                setLoading(true);
                const response = await fetch(
                    `${baseUrl}/meetings/getSchedulePageData/${username}/${meetingId}`,
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            // Authorization: `Bearer ${token}`,
                        },
                    }
                );

                if (response.status === 200) {
                    const data = await response.json();
                    setUser(data.data.user);
                    setAvailability(data.data.availability);
                    setEvents(data.data.events);
                    setLoading(false);
                } else {
                    console.error("Failed to fetch schedule page data");
                    setLoading(false);
                }
            } catch (error) {
                console.error("Error:", error);
                setLoading(false);
            }
        };

        fetchSchedulePageData();
    }, [username, meetingId]);

    const calculateDiscountedPrice = (price, discountPercentage) => {
        if (discountPercentage && discountPercentage > 0) {
            const discountAmount = (price * discountPercentage) / 100;
            return price - discountAmount;
        }
        return price;
    };

    // Schedule meeting with server
    const scheduleMeeting = async (meetingData) => {
        try {
            const response = await fetch(`${baseUrl}/meetings/scheduleMeeting`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ meetingData }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || "Failed to schedule meeting");
            }

            const data = await response.json();
            setMeetingLink(data.data.meetingLink);
            setIsBooked(true);
            return data;
        } catch (error) {
            console.error("Meeting scheduling failed:", error);
            setError(error.message);
            throw error;
        }
    };

    // Add Cashfree initialization function
    const initializeCashfree = async () => {
        try {
            return await load({ mode: "production" });
        } catch (error) {
            console.error("Failed to initialize Cashfree:", error);
            throw error;
        }
    };

    // Add payment verification function
    const verifyPayment = async (orderId, entityId) => {
        try {
            const verificationResponse = await fetch(
                `${baseUrl}/meetings/verify-payment`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        orderId,
                        entityId,
                        entityType: 'meeting'
                    }),
                }
            );

            const data = await verificationResponse.json();
            if (data.data.status === "SUCCESS") {
                toast.success('Payment successful!');
                return true;
            } else {
                toast.error('Payment verification failed');
                return false;
            }
        } catch (error) {
            console.error('Verification error:', error);
            toast.error('Payment verification failed');
            return false;
        }
    };

    // Modify handleSchedule to handle payments
    const handleSchedule = async (e) => {
        e.preventDefault();
        setError("");

        if (!selectedDate || !selectedTime) {
            setError("Please select a date and time before scheduling.");
            return;
        }

        const startTime = selectedTime;
        const [startHour, startMinute] = startTime.split(":").map(Number);
        const duration = events[0]?.duration || 30;
        const endDate = new Date();
        endDate.setHours(startHour);
        endDate.setMinutes(startMinute + duration);
        const endTime = `${endDate.getHours().toString().padStart(2, "0")}:${endDate.getMinutes().toString().padStart(2, "0")}`;

        const meetingData = {
            startTime,
            endTime,
            date: selectedDate.toLocaleDateString("en-US", {
                day: "numeric",
                month: "long",
                year: "numeric"
            }),
            name: e.target.name.value,
            email: e.target.email.value,
            additionalInfo: e.target.additionalInfo.value,
            eventId: meetingId,
            username: username,
            paymentAmount: calculateDiscountedPrice(events[0]?.price, events[0]?.discount) || 0,
        };

        if (events[0]?.price > 0) {
            setMeetingDetails(meetingData);
            setShowPaymentModal(true);
        } else {
            try {
                setLoading(true);
                meetingData.paymentStatus = "Not Required";
                meetingData.paymentId = null;
                await scheduleMeeting(meetingData);
            } catch (error) {
                setError(error.message || "An error occurred during scheduling");
            } finally {
                setLoading(false);
            }
        }
    };

    // Add payment success handler
    const handlePaymentSuccess = async (userDetails) => {
        try {
            setLoading(true);
            const cashfree = await initializeCashfree();

            const paymentResponse = await fetch(`${baseUrl}/meetings/create-payment-session`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    name: userDetails.name,
                    email: userDetails.email,
                    mobile: userDetails.mobile,
                    amount: calculateDiscountedPrice(events[0]?.price, events[0]?.discount),
                    entityId: meetingId,
                    entityType: 'meeting'
                }),
            });

            const paymentData = await paymentResponse.json();
            const { order_id, payment_session_id } = paymentData.data;

            await cashfree.checkout({
                paymentSessionId: payment_session_id,
                redirectTarget: "_modal",
            });

            const verificationResult = await verifyPayment(order_id, meetingId);
            if (verificationResult) {
                meetingDetails.paymentStatus = "Completed";
                meetingDetails.paymentId = order_id;
                await scheduleMeeting(meetingDetails);
            }
        } catch (error) {
            console.error('Payment error:', error);
            toast.error('Payment process failed');
        } finally {
            setShowPaymentModal(false);
            setLoading(false);
        }
    };

    const isDateAvailable = (date) => {
        const dayOfWeek = date.getDay();
        const availabilityDays = [
            "sunday",
            "monday",
            "tuesday",
            "wednesday",
            "thursday",
            "friday",
            "saturday",
        ];

        const currentDayName = availabilityDays[dayOfWeek];
        const dayAvailability = availability?.dayAvailability?.find(
            (availability) => availability.day === currentDayName
        );

        return !!dayAvailability; // Returns true if the day exists in availability
    };

    const generateTimeSlots = (selectedDate) => {
        if (!selectedDate || !availability?.dayAvailability) return [];

        const dayOfWeek = selectedDate.getDay();
        const availabilityDays = [
            "sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"
        ];

        const currentDayName = availabilityDays[dayOfWeek];
        const dayAvailability = availability.dayAvailability.find(
            (avail) => avail.day === currentDayName
        );

        if (!dayAvailability) return [];

        // Parse start time
        let startHour, startMinute;
        if (dayAvailability.startTime.includes('AM') || dayAvailability.startTime.includes('PM')) {
            const [time, period] = dayAvailability.startTime.split(' ');
            const [hours, minutes] = time.split(':').map(Number);
            startHour = period === 'PM' && hours !== 12 ? hours + 12 : hours;
            startMinute = minutes || 0;
        } else {
            [startHour, startMinute] = dayAvailability.startTime.split(':').map(Number);
        }

        // Parse end time
        let endHour, endMinute;
        if (dayAvailability.endTime.includes('AM') || dayAvailability.endTime.includes('PM')) {
            const [time, period] = dayAvailability.endTime.split(' ');
            const [hours, minutes] = time.split(':').map(Number);
            endHour = period === 'PM' && hours !== 12 ? hours + 12 : hours;
            endMinute = minutes || 0;
        } else {
            [endHour, endMinute] = dayAvailability.endTime.split(':').map(Number);
        }

        const slots = [];
        const minimumGap = availability.minimumGap || 30;
        const meetingDuration = events[0]?.duration || 30;

        // Get existing bookings for the selected date
        const existingBookings = events[0]?.bookings?.filter(booking => {
            const bookingDate = new Date(booking.date);
            return bookingDate.toDateString() === selectedDate.toDateString();
        }) || [];

        // Convert bookings to time ranges with buffer times
        const bookedRanges = existingBookings.map(booking => {
            const startTime = new Date(booking.startTime);
            const endTime = new Date(booking.endTime);
            return {
                start: startTime.getUTCHours() * 60 + startTime.getUTCMinutes(),
                end: endTime.getUTCHours() * 60 + endTime.getUTCMinutes(),
                bufferBefore: minimumGap, // Buffer time before booking
                bufferAfter: minimumGap   // Buffer time after booking
            };
        });

        // Sort booked ranges by start time
        bookedRanges.sort((a, b) => a.start - b.start);

        // Generate all possible time slots
        let currentMinutes = startHour * 60 + startMinute;
        const endMinutes = endHour * 60 + endMinute;

        while (currentMinutes + meetingDuration <= endMinutes) {
            const slotEnd = currentMinutes + meetingDuration;
            const timeString = `${Math.floor(currentMinutes / 60).toString().padStart(2, '0')}:${(currentMinutes % 60).toString().padStart(2, '0')}`;
            
            // Check if this is a booked slot
            const isBooked = bookedRanges.some(range => 
                currentMinutes === range.start
            );

            // Check if slot overlaps with any booking or buffer times
            const isBufferZone = bookedRanges.some(range => {
                const bufferStartTime = range.start - range.bufferBefore;
                const bufferEndTime = range.end + range.bufferAfter;
                return (currentMinutes < bufferEndTime && slotEnd > bufferStartTime);
            });

            if (isBooked) {
                // Add the booked slot
                slots.push({
                    time: timeString,
                    isBooked: true
                });
                
                // Find the current booking
                const currentBooking = bookedRanges.find(range => range.start === currentMinutes);
                if (currentBooking) {
                    // Jump to after this booking's end time + buffer
                    currentMinutes = currentBooking.end + currentBooking.bufferAfter;
                    continue;
                }
            } else if (isBufferZone) {
                // Skip slots in buffer zones
                currentMinutes += meetingDuration;
                continue;
            } else {
                // Add available slot
                slots.push({
                    time: timeString,
                    isBooked: false
                });
                currentMinutes += meetingDuration;
            }
        }

        return slots;
    };

    // Replace the static timeSlots array with dynamic generation
    const [availableTimeSlots, setAvailableTimeSlots] = useState([]);

    useEffect(() => {
        if (selectedDate) {
            const slots = generateTimeSlots(selectedDate);
            setAvailableTimeSlots(slots);
        }
    }, [selectedDate, availability]);

    const handleDateChange = (date) => {
        if (isDateAvailable(date)) {
            setSelectedDate(date);
            setCurrentDate(date);
        }
    };

    const formatPrice = (price) => {
        return price === 0 ? "Free" : `₹${price.toFixed(0)}`;
    };

    return (
		<>
			<Navbar />
			<div className="meeting-scheduler" data-bs-theme={theme}>
				<div className="meeting-scheduler__container">
					{/* Left Container */}
                <div className="meeting-scheduler__left">
                    {/* Header Section */}
                    <div className="header-section">
                        <button
                            className="back-button"
                            onClick={() => window.history.back()}
                        >
                            <FaChevronLeft /> Back
                        </button>
                        <div className="profile">
                            <img
                                src={user.profilePicture || avatar4}
                                className="profile__image"
                                alt="Profile"
                            />
                            <div className="profile__info">
                                <h2>{events.map((event) => event.title || "")}</h2>
                                <p>{user.email}</p>
                            </div>
                        </div>
                    </div>

                    {/* Details Section */}
                    <div className="details-section">
                        <div className="meeting-info">
                            <FaCalendarAlt />
                            <span>{events.map((event) => event.duration || 30)} minutes</span>
                        </div>

                        <div className="meeting-info">
                            <FaVideo />
                            <span>Google Meet</span>
                        </div>

                        <div className="meeting-info price-info">
                            <ImPriceTags />
                            <div className="price">
                                {events[0]?.price > 0 ? (
                                    <div className="price-tag">
                                        {events[0]?.discount > 0 && (
                                            <span className="original-price">
                                                {formatPrice(events[0]?.price)}
                                            </span>
                                        )}
                                        <span className="new-price">
                                            {formatPrice(
                                                calculateDiscountedPrice(
                                                    events[0]?.price,
                                                    events[0]?.discount
                                                )
                                            )}
                                        </span>
                                    </div>
                                ) : (
                                    <span className="free-tag">Free</span>
                                )}
                            </div>
                        </div>

                        <div className="description-section">
                            <h4>About this meeting</h4>
                            <p>{events.map((event) => event.description || "")}</p>
                        </div>
                    </div>
                </div>

                {/* Right Container */}
                <div className="meeting-scheduler__right">
                    {!isBooked ? (
                        <>
                            <div className="calendar-section">
                                <Calendar
                                    onChange={handleDateChange}
                                    value={currentDate}
                                    minDate={new Date()}
                                    tileDisabled={({ date }) => !isDateAvailable(date)}
                                    prevLabel={<FaChevronLeft />}
                                    nextLabel={<FaChevronRight />}
                                    className="custom-calendar"
                                />
                            </div>

                            <div className="time-slots">
                                <h4>Available Time Slots</h4>
                                <div className="time-slots__grid">
                                    {availableTimeSlots.map(({ time, isBooked }) => (
                                        <button
                                            key={time}
                                            className={`${selectedTime === time ? "selected" : ""} ${isBooked ? "booked" : ""}`}
                                            onClick={() => !isBooked && setSelectedTime(time)}
                                            disabled={isBooked}
                                        >
                                            {time}
                                        </button>
                                    ))}
                                    {availableTimeSlots.length === 0 && selectedDate && (
                                        <p>No available time slots for this date.</p>
                                    )}
                                </div>
                            </div>

                            <button
                                className="confirm-details-btn"
                                onClick={() => setShowDetailsForm(true)}
                                disabled={!selectedDate || !selectedTime}
                            >
                                Confirm Details
                            </button>

                            {/* Details Form Modal */}
                            {showDetailsForm && (
                                <div className="details-form-modal">
                                    <div className="modal-content">
                                        <button
                                            className="back-button"
                                            onClick={() => setShowDetailsForm(false)}
                                        >
                                            <FaChevronLeft /> Back
                                        </button>

                                        <div className="form-section">
                                            <h3>Additional Details</h3>
                                            <form onSubmit={handleSchedule}>
                                                <input
                                                    type="text"
                                                    name="name"
                                                    placeholder="Your Name"
                                                    required
                                                />
                                                <input
                                                    type="email"
                                                    name="email"
                                                    placeholder="Your Email"
                                                    required
                                                />
                                                <textarea
                                                    name="additionalInfo"
                                                    placeholder="Additional Information"
                                                    rows={4}
                                                />

                                                {events[0]?.price > 0 && (
                                                    <div className="order-summary">
                                                        <h4>Order Summary</h4>
                                                        <div className="summary-item">
                                                            <span>Meeting Duration</span>
                                                            <span>{events[0]?.duration} minutes</span>
                                                        </div>
                                                        <div className="summary-item">
                                                            <span>Price</span>
                                                            <span>{formatPrice(events[0]?.price)}</span>
                                                        </div>
                                                        {events[0]?.discount > 0 && (
                                                            <div className="summary-item discount">
                                                                <span>Discount ({events[0]?.discount}%)</span>
                                                                <span>
                                                                    -
                                                                    {formatPrice(
                                                                        (events[0]?.price * events[0]?.discount) /
                                                                            100
                                                                    )}
                                                                </span>
                                                            </div>
                                                        )}
                                                        <div className="summary-item total">
                                                            <span>Total</span>
                                                            <span>
                                                                {formatPrice(
                                                                    calculateDiscountedPrice(
                                                                        events[0]?.price,
                                                                        events[0]?.discount
                                                                    )
                                                                )}
                                                            </span>
                                                        </div>
                                                    </div>
                                                )}

                                                <button type="submit" disabled={loading}>
                                                    {events[0]?.price > 0
                                                        ? "Schedule and Pay"
                                                        : "Schedule Event"}
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                    ) : (
                        <div className="success-message">
                            <h3>Booking successful!</h3>
                            <div className="meeting-link-container">
                                <h4>Join the meeting:</h4>
                                <a href={meetingLink} className="meeting-link">
                                    {meetingLink || "https://meet.google.com/hur-sdhb-mnu"}
                                </a>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            {/* Add Payment Modal */}
            {showPaymentModal && meetingDetails && (
                <PurchasePopup
                    isOpen={showPaymentModal}
                    onClose={() => setShowPaymentModal(false)}
                    itemDetails={{
                        type: 'meeting',
                        name: events[0]?.title,
                        description: events[0]?.description,
                        amount: calculateDiscountedPrice(events[0]?.price, events[0]?.discount),
                        is_free: events[0]?.price === 0
                    }}
                    onProceed={handlePaymentSuccess}
                />
            )}
        </div>
		</>
    );
};

export default MeetingScheduler;

