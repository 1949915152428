import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	selectTheme,
	selectIsMobileView,
} from "../../../Store/features/design/designSlice";
import { Link, useNavigate } from "react-router-dom";
import DarkLogo from "../../../Images/investorIcon/new-logo.png";
import WhiteLogo from "../../../Images/investorIcon/logo-white.png";
import OnboardingSwitch from "../../../components/Investor/InvestorNavbar/OnboardingSwitch/OnboardingSwitch";
import { BsBell } from "react-icons/bs";
import { AiOutlineMessage } from "react-icons/ai";
import { MdMenu, MdMenuOpen } from "react-icons/md";
import { selectTotalUnreadCount } from "../../../Store/features/chat/chatSlice";
import { setUnreadNotifications } from "../../../Store/features/user/userSlice";
import NavPopup from "./Popup/NavPopup";
import "./Navbar3.scss";

const Navbar3 = ({ handleSidebarToggle, sidebarCollapse }) => {
	const theme = useSelector(selectTheme);
	const user = useSelector((state) => state.user.loggedInUser);
	const isMobileView = useSelector(selectIsMobileView);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const totalUnreadCount = useSelector(selectTotalUnreadCount);
	const [showProfileDropdown, setShowProfileDropdown] = useState(false);
	const [activePopup, setActivePopup] = useState(null);
	const notificationRef = useRef(null);
	const messageRef = useRef(null);

	useEffect(() => {
		function handleClickOutside(event) {
			if (
				notificationRef.current &&
				!notificationRef.current.contains(event.target) &&
				messageRef.current &&
				!messageRef.current.contains(event.target)
			) {
				setActivePopup(null);
			}
		}

		document.addEventListener("click", handleClickOutside);
		return () => {
			document.removeEventListener("click", handleClickOutside);
		};
	}, []);

	const handleNotificationClick = () => {
		setActivePopup((prev) => (prev === "notification" ? null : "notification"));
		dispatch(setUnreadNotifications(0));
	};

	const handleMessageClick = () => {
		setActivePopup((prev) => (prev === "message" ? null : "message"));
	};

	const handleProfileClick = () => {
		setShowProfileDropdown(!showProfileDropdown);
	};

	const handleLogout = () => {
		// Implement logout logic here
		// For example: dispatch(logoutUser())
		navigate("/login");
	};

	return (
		<>
			<div
				className={`navbar-public-container ${
					theme === "dark" ? "dark-theme" : ""
				}`}
			>
				<div className="navbar-public-logo">
					<div className="navbar-public-sidebar-toggle">
						{sidebarCollapse ? (
							<MdMenu size={25} onClick={handleSidebarToggle} />
						) : (
							<MdMenuOpen
								size={25}
								onClick={handleSidebarToggle}
								style={{ fill: "#fd5901" }}
							/>
						)}
					</div>
					<img
						src={theme === "dark" ? WhiteLogo : DarkLogo}
						onClick={() => navigate("/")}
						alt="the capital hub logo"
					/>
				</div>
				
				
			</div>
		</>
	);
};

export default Navbar3;
