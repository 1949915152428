import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { environment } from '../../../environments/environment';
import { selectLoggedInUserId } from '../../../Store/features/user/userSlice';
import About from './About/About';
import { toast } from 'react-hot-toast';
import "./PublicCommunityView.scss"
import PostCard from "../../PublicPostPage/PostCard/PostCard";
import { getAllCommunityPostsAPI } from "../../../Service/user";
import Modal from 'react-modal';
import { sendOTP, verifyOTP, postUserLogin } from "../../../Service/user";
import { loginSuccess } from '../../../Store/features/user/userSlice';
import avatar4 from "../../../Images/avatars/image-2.png";
import Picture1 from "../../../pages/StartUp/Resources/ResourcesNew/images/Picture1.png";
import Picture2 from "../../../pages/StartUp/Resources/ResourcesNew/images/Picture2.png";
import Register from '../../../components/Register/Register';
Modal.setAppElement('#root');

// Add these dummy data constants at the top of the file
const DUMMY_PRODUCTS = [
  {
    _id: 'dummy1',
    name: 'Premium Course',
    description: 'Learn advanced strategies and techniques',
    image: Picture1,
    is_free: false,
    amount: 999
  },
  {
    _id: 'dummy2',
    name: 'Community Resources',
    description: 'Access exclusive community materials',
    image: Picture2,
    is_free: true,
    amount: 0
  }
];

const DUMMY_EVENTS = [
  {
    _id: 'event1',
    title: 'Networking Meetup',
    duration: 120,
    eventType: 'Online',
    price: 0
  },
  {
    _id: 'event2',
    title: 'Expert Workshop',
    duration: 90,
    eventType: 'Online',
    price: 499
  }
];

const PublicCommunityView = () => {
  const { communityId } = useParams();
  const [community, setCommunity] = useState(null);
  const navigate = useNavigate();
  const loggedInUserId = useSelector(selectLoggedInUserId);
  const [adminPosts, setAdminPosts] = useState([]);
  const [showSignupModal, setShowSignupModal] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [isVerifyingOtp, setIsVerifyingOtp] = useState(false);
  const [orderId, setOrderId] = useState("");
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const inputRefs = useRef([]);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    userType: "startup founder",
    registeredFrom: "community-page"
  });
  const dispatch = useDispatch();
  const [isJoining, setIsJoining] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  useEffect(() => {
    fetchCommunityDetails();
    console.log(community);
  }, [communityId]);

  useEffect(() => {
   if(community){
      fetchAdminPosts();
      console.log(adminPosts);
   }
  }, [community]);

  const fetchCommunityDetails = async () => {
    try {
      const response = await axios.get(
        `${environment.baseUrl}/communities/getCommunityById/${communityId}`
      );
      setCommunity(response.data.data);
    } catch (error) {
      console.error("Error fetching community:", error);
    }
  };

  const fetchAdminPosts = async () => {
    try {
      const response = await getAllCommunityPostsAPI(1);  
      const postsData = Array.isArray(response.data) ? response.data : 
                       Array.isArray(response.data?.data) ? response.data.data : [];  
      const communityPosts = postsData.filter(
        (item) =>
          item.communityId === community._id &&
          item.user._id === community?.adminId._id
      ).slice(0, 3);
      setAdminPosts(communityPosts);
    } catch (error) {
      console.error("Error fetching admin posts:", error);
    }
  };

  const handleLockedContentClick = () => {
    if (!loggedInUserId) {
      setShowSignupModal(true);
    } else {
      handleJoinCommunity();
    }
  };

  const handleJoinCommunity = async () => {
    if (!community.isOpen) {
      toast.error('This community is closed for new members');
      return;
    }

    setIsJoining(true);
    try {
      const token = localStorage.getItem('accessToken');
      const response = await axios.post(
        `${environment.baseUrl}/communities/addMembersToCommunity/${communityId}`,
        {
          memberIds: [loggedInUserId],
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data?.error === 'User is already a member') {
        toast.error('You are already a member of this community');
        return;
      }

      setShowSuccessModal(true);
    } catch (error) {
      console.error('Error joining community:', error);
      const errorMessage = error.response?.data?.message || 'Failed to join the community';
      toast.error(errorMessage);
    } finally {
      setIsJoining(false);
    }
  };

  const handleSignupSubmit = async (e) => {
    e.preventDefault();
    try {
      const phoneNumberWithPrefix = `+91${formData.phoneNumber}`;
      const response = await sendOTP(phoneNumberWithPrefix);
      setOrderId(response?.orderId);
      setShowOtpModal(true);
      setShowSignupModal(false);
    } catch (error) {
      toast.error("Failed to send OTP. Please try again.");
    }
  };

  const handleOtpVerify = async () => {
    setIsVerifyingOtp(true);
    try {
      const verificationCode = otp.join("");
      
      const otpResponse = await verifyOTP({
        otp: verificationCode,
        orderId,
        phoneNumber: `+91${formData.phoneNumber}`,
      });

      if (otpResponse.isOTPVerified) {
        console.log("OTP verified successfully, proceeding with registration...");
        
        // First register the user
        const registrationData = {
          ...formData,
          isInvestor: formData.userType === "investor",
          registeredFrom: "community-page",
        };
        console.log("Registration data:", registrationData);
        
        const registrationResponse = await axios.post(
          `${environment.baseUrl}/users/registerUser`,
          registrationData
        );
        console.log("Registration response:", registrationResponse);

        // Then login the user
        const loginResponse = await postUserLogin({
          phoneNumber: formData.phoneNumber,
        });
        console.log("Login response:", loginResponse);

        if (loginResponse?.user && loginResponse?.token) {
          const { user, token } = loginResponse;
          
          // Store user data
          localStorage.setItem("accessToken", token);
          localStorage.setItem("isLoggedIn", "true");
          localStorage.setItem("user", JSON.stringify(user));
          
          // Update Redux state
          dispatch(loginSuccess(user));
          
          // Close modals
          setShowOtpModal(false);
          setShowSignupModal(false);
          
          toast.success('Successfully registered and logged in!');
          
          // Join community
          console.log("Attempting to join community...");
          await handleJoinCommunity();
        } else {
          console.error("Login response missing user or token:", loginResponse);
          toast.error("Login failed after registration");
        }
      } else {
        console.error("OTP verification failed:", otpResponse);
        toast.error("Invalid OTP. Please try again.");
      }
    } catch (error) {
      console.error("Error in handleOtpVerify:", error);
      toast.error(error.response?.data?.message || "Registration failed");
    } finally {
      setIsVerifyingOtp(false);
      setOtp(["", "", "", "", "", ""]); // Reset OTP input
    }
  };

  const handleOtpChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Auto-focus next input
    if (value && index < 5) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (index, e) => {
    // Handle backspace
    if (e.key === 'Backspace' && !otp[index] && index > 0) {
      const newOtp = [...otp];
      newOtp[index - 1] = '';
      setOtp(newOtp);
      inputRefs.current[index - 1].focus();
    }
  };

  const renderOtpModal = () => (
    <Modal
      isOpen={showOtpModal}
      onRequestClose={() => {
        setShowOtpModal(false);
        setOtp(["", "", "", "", "", ""]);
      }}
      className="public-community-modal-content"
      overlayClassName="modal-overlay"
      shouldCloseOnOverlayClick={!isVerifyingOtp}
    >
      <div className="modal-header">
        <h2>Enter verification code</h2>
        <button 
          onClick={() => {
            setShowOtpModal(false);
            setOtp(["", "", "", "", "", ""]);
          }}
          disabled={isVerifyingOtp}
        >
          ×
        </button>
      </div>
      <p>We have just sent a verification code to your mobile number.</p>
      <div className="otp-container">
        {otp.map((digit, index) => (
          <input
            key={index}
            ref={(el) => (inputRefs.current[index] = el)}
            type="text"
            maxLength="1"
            value={digit}
            onChange={(e) => handleOtpChange(index, e.target.value)}
            onKeyDown={(e) => handleKeyDown(index, e)}
            disabled={isVerifyingOtp}
          />
        ))}
      </div>
      <button 
        onClick={handleOtpVerify} 
        disabled={isVerifyingOtp || otp.join("").length !== 6}
      >
        {isVerifyingOtp ? "Verifying..." : "Verify"}
      </button>
    </Modal>
  );

  const renderSuccessModal = () => (
    <Modal
      isOpen={showSuccessModal}
      onRequestClose={() => setShowSuccessModal(false)}
      className="public-community-modal-content success-modal"
      overlayClassName="modal-overlay"
    >
      <div className="modal-header">
        <h2>Congratulations! 🎉</h2>
        <button onClick={() => setShowSuccessModal(false)}>×</button>
      </div>
      <p>You are now a part of {community?.name}!</p>
      <button 
        onClick={() => {
          setShowSuccessModal(false);
          window.location.reload();
        }}
        className="explore-button"
      >
        Explore the Community
      </button>
    </Modal>
  );

  return (
    <div className="public-community-view">
      <About community={community} />
      
      {/* Posts Section */}
      <div className="content-section posts-section">
        <h3>Recent Posts from Admin</h3>
        <div className="admin-posts-content locked-content">
          {adminPosts.map(post => (
            <PostCard
              key={post._id}
              firstName={post.user.firstName}
              lastName={post.user.lastName}
              profilePicture={post.user.profilePicture || avatar4} 
              oneLinkId={post.user.oneLinkId}
              isSubscribed={false}
              startUpCompanyName={post.user.startUp}
              investorCompanyName={post.user.investor}
              designation={post.user.designation}
              location={post.user.location}
              description={post.description}
              video={post.video}
              image={post.image}
              images={post.images}
              createdAt={post.createdAt}
              likes={post.likes}
              comments={post.comments}
              pollOptions={post.pollOptions}
              userName={post.user.userName}
            />
          ))}
          <div className="locked-overlay" onClick={handleLockedContentClick}>
            <div className="lock-message">
              {loggedInUserId ? 'Join community to access all posts' : 'Sign up to access all posts'}
            </div>
          </div>
        </div>
      </div>

      {/* Products Section */}
      <div className="content-section products-section">
        <h3>Community Products</h3>
        <div className="products-grid locked-content">
          {(community?.products?.length > 0 ? community.products.slice(0, 3) : DUMMY_PRODUCTS).map(product => (
            <div key={product._id} className="product-card">
              <div className="product-image">
                <img src={product.image} alt={product.name} />
                <span className={`badge ${product.is_free ? 'free' : 'paid'}`}>
                  {product.is_free ? 'Free' : `₹${product.amount}`}
                </span>
              </div>
              <div className="product-content">
                <h4>{product.name}</h4>
                <p>{product.description}</p>
              </div>
            </div>
          ))}
          <div className="locked-overlay" onClick={handleLockedContentClick}>
            <div className="lock-message">
              {loggedInUserId ? 'Join community to access products' : 'Sign up to access products'}
            </div>
          </div>
        </div>
      </div>

      {/* Events Section */}
      <div className="content-section events-section">
        <h3>Upcoming Events</h3>
        <div className="events-grid locked-content">
          {(community?.events?.length > 0 ? community.events : DUMMY_EVENTS).map(event => (
            <div key={event._id} className="event-card">
              <div className="event-info">
                <h3>{event.title}</h3>
                <div className="event-meta">
                  <div className="leftTime">
                    <span>{event.duration} mins</span>
                    <span className="separator">|</span>
                    <span>{event.eventType}</span>
                  </div>
                  <div className="price-tag">
                    {event.price > 0 ? `₹${event.price}` : 'Free'}
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div className="locked-overlay" onClick={handleLockedContentClick}>
            <div className="lock-message">
              {loggedInUserId ? 'Join community to access events' : 'Sign up to access events'}
            </div>
          </div>
        </div>
      </div>

      {showSignupModal && (
        <div className="modal-overlay">
          <Register 
            setShowSignupModal={setShowSignupModal}
            registeredFrom={window.location.pathname}
          />
        </div>
      )}
      {renderOtpModal()}
      {renderSuccessModal()}
    </div>
  );
};

export default PublicCommunityView; 